<template>
  <div class="warp">
    <div class="main">
      <p>注册须知：</p>
      <p>对不起，清运员身份暂未支持申请产废企业，如有任何疑问请联系平台工作人员</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
}

</script>
<style lang="scss" scoped>
.warp {
  padding: 30px;
  box-sizing: border-box;
  .main {
    p {
      line-height: 27px;
      font-size: 14px;
    }
  }
}
</style>
